export const HALF_FRONTEND = {
    name: "ALEXIS",
    title: "Development",
    style: "left",
};

export const HALF_DESIGN = {
    name: "TOLEDO",
    title: "Graphic Design",
    style: "right",
};

export const ABOUT_DATA = {
    text: [
        "A 29 years old Front End Developer based in Copenhagen, Denmark. I have been working in websites (HTML + CSS + JS) for over ten years and more than three using modern Javascript frameworks as React. I have strong knowledge in almost all stages of development.",
        "Besides, I am a Graphic Designer with extensive experience in the Creative Cloud Suite, so I have strong expertise in designing solid, user-friendly, modern and eye-catching UI's, adding this way value to the final result.",
        "I'm a very responsible person who is used to work both individually and as a team, always sticking to deadlines, and willing to get things done of the best way possible.",
    ],
    writtenSkills:
        "I have proficiency in HTML, CSS and Javascript, technologies I've used for almost a decade. I also have good knowledge of React and React Native which I'm working on improving. Besides, I have experience working with Git/Github and Firebase, SASS and currently learning Typescript and NodeJS.",
    skills: [
        {
            type: "html",
            name: "HTML5",
            newValue: 91,
            color: "#e54e26",
        },
        {
            type: "css",
            name: "CSS3",
            newValue: 82,
            color: "#0c6fb8",
        },
        {
            type: "js",
            name: "Javascript",
            newValue: 74,
            color: "#e8a52c",
        },
        {
            type: "react",
            name: "React",
            newValue: 62,
            color: "#167abf",
        },
        {
            type: "sass",
            name: "Sass",
            newValue: 21,
            color: "#cd669a",
        },
        {
            type: "github",
            name: "GitHub",
            newValue: 47,
            color: "#333333",
        },
        {
            type: "firebase",
            name: "Firebase",
            newValue: 38,
            color: "#f4a73e",
        },
        {
            type: "node",
            name: "NodeJS",
            newValue: 28,
            color: "#539e43",
        },
    ],
};

/* 
██████╗░███████╗██╗░░░██╗███████╗██╗░░░░░░█████╗░██████╗░███╗░░░███╗███████╗███╗░░██╗████████╗
██╔══██╗██╔════╝██║░░░██║██╔════╝██║░░░░░██╔══██╗██╔══██╗████╗░████║██╔════╝████╗░██║╚══██╔══╝
██║░░██║█████╗░░╚██╗░██╔╝█████╗░░██║░░░░░██║░░██║██████╔╝██╔████╔██║█████╗░░██╔██╗██║░░░██║░░░
██║░░██║██╔══╝░░░╚████╔╝░██╔══╝░░██║░░░░░██║░░██║██╔═══╝░██║╚██╔╝██║██╔══╝░░██║╚████║░░░██║░░░
██████╔╝███████╗░░╚██╔╝░░███████╗███████╗╚█████╔╝██║░░░░░██║░╚═╝░██║███████╗██║░╚███║░░░██║░░░
╚═════╝░╚══════╝░░░╚═╝░░░╚══════╝╚══════╝░╚════╝░╚═╝░░░░░╚═╝░░░░░╚═╝╚══════╝╚═╝░░╚══╝░░░╚═╝░░░
 */
export const DEV_PROJECTS = [
    {
        id: 13,
        type: "development",
        name: "Your own TML",
        description:
            "I created this app to solve a very niche problem: there's no official app to arrange your own schedule for one of the biggest festivals in the world, with more than 350 shows distributed among 14 stages at the same time.",
        role: "Main and only developer",
        launchdate: "June 2022",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Try it now",
                link: "https://yourowntml.web.app/",
                style: "enabled",
            },
            {
                id: "btn2",
                type: "github",
                text: "Repository",
                link: "https://github.com/alexisjtoledo/yourownTML",
                style: "enabled",
            },
            {
                id: "btn3",
                type: "website",
                text: "Reddit thread",
                link: "https://www.reddit.com/r/Tomorrowland/comments/v8lfps/app_to_arrange_your_tml_schedule/",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/yourowntml/01.png",
                alt: "Alexis's new portfolio",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/yourowntml/02.png",
                alt: "Alexis Toledo's new portfolio",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/yourowntml/03.png",
                alt: "Alexis's new portfolio",
            },
        ],
        picturesQuantity: 3,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: true,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: true,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: false,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 12,
        type: "development",
        name: "Personal Portfolio v2",
        description:
            "The new version of my personal portfolio is a Simple Single Page Application using React. Most of its micro-interactions were developed from scratch.",
        role: "Main and only developer",
        launchdate: "June 2021",
        buttons: [
            {
                id: "btn1",
                type: "github",
                text: "Repository",
                link: "https://github.com/alexisjtoledo/newportfolio",
                style: "enabled",
            },
            {
                id: "btn2",
                type: "website",
                text: "Visit",
                link: "https://alexistoledo.dev",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/newportfolio/01.png",
                alt: "Alexis's new portfolio",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/newportfolio/02.png",
                alt: "Alexis Toledo's new portfolio",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/newportfolio/03.png",
                alt: "Alexis's new portfolio",
            },
        ],
        picturesQuantity: 3,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: true,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: true,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 11,
        type: "development",
        name: "FMderecho App v2",
        description:
            "Hybrid app with a UX inspired by Netflix's and Instagram's applications. Was created to make easier the life of more than 10000 students of the Law School of the National University of Córdoba.",
        role: "Main and only developer",
        launchdate: "Postponed until Argentina go back to classrooms.",
        buttons: [
            {
                id: "btn1",
                type: "playstore",
                text: "Get App",
                link: "https://play.google.com/store/apps/details?id=com.fmderecho.fmapp",
                style: "enabled",
            },
            {
                id: "btn2",
                type: "appstore",
                text: "Get App",
                link: "#",
                style: "disabled",
            },
            {
                id: "btn3",
                type: "video",
                text: "Overview",
                link: "https://www.youtube.com/watch?v=PaNxM35Pgm8",
                style: "enabled",
            },
            {
                id: "btn4",
                type: "github",
                text: "Repository",
                link: "https://github.com/alexisjtoledo/fmderecho",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/fmderecho/01.png",
                alt: "FM derecho new mobile application",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/fmderecho/02.png",
                alt: "FM derecho new mobile application",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/fmderecho/03.png",
                alt: "FM derecho new mobile application",
            },
        ],
        picturesQuantity: 3,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: true,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: true,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: true,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: true,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 10,
        type: "development",
        name: "Auto parts Management Software",
        description:
            "It was practical teamwork for college (National Technological University). It's a management software for a mid-small auto part company.",
        role: "Front-end design, Query form, Filters & some database modeling.",
        launchdate: "November 2019",
        buttons: [
            {
                id: "btn4",
                type: "github",
                text: "Repository",
                link: "https://github.com/alexisjtoledo/TPI-automotores",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/autoparts/01.png",
                alt: "Autoparts Management Software",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/autoparts/02.png",
                alt: "Autoparts Management Software",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/autoparts/03.png",
                alt: "Autoparts Management Software",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/autoparts/04.png",
                alt: "Autoparts Management Software",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/autoparts/05.png",
                alt: "Autoparts Management Software",
            },
        ],
        picturesQuantity: 5,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: true,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: true,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: true,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 9,
        type: "development",
        name: "Personal Portfolio v1",
        description:
            "Was the first version of my personal portfolio. Designed using React, some Bootstrap components and personalized styles.",
        role: "Main and only developer",
        launchdate: "August 2019",
        buttons: [
            {
                id: "btn1",
                type: "github",
                text: "Repository",
                link: "https://github.com/alexisjtoledo/personal_resume",
                style: "enabled",
            },
            {
                id: "btn2",
                type: "website",
                text: "Visit",
                link: "https://alexisjtoledo.github.io/personal_resume/",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/oldportfolio/01.png",
                alt: "Alexis's old portfolio",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/oldportfolio/02.png",
                alt: "Alexis's old portfolio",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/oldportfolio/03.png",
                alt: "Alexis's old portfolio",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/oldportfolio/04.png",
                alt: "Alexis's old portfolio",
            },
        ],
        picturesQuantity: 4,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: true,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: true,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 8,
        type: "development",
        name: "Municipal Congress of Education App",
        description:
            "The idea behind this app was to reduce the use of paper in the Annual Congress by using the app as a main communication for the assistants. Also, was used for attendance control.",
        role: "Main and only developer",
        launchdate: "October 2018",
        buttons: [
            {
                id: "btn1",
                type: "playstore",
                text: "Get App",
                link: "https://apkpure.com/es/congreso-municipal-de-educaci%C3%B3n/ar.gob.cordoba.congreso_municipal_educacion",
                style: "enabled",
            },
            {
                id: "btn2",
                type: "github",
                text: "Repository",
                link: "https://github.com/ModernizacionMuniCBA/cme_municba",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/cme/01.png",
                alt: "Municipal Education Congress App",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/cme/02.png",
                alt: "Municipal Education Congress App",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/cme/03.png",
                alt: "Municipal Education Congress App",
            },
        ],
        picturesQuantity: 3,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 7,
        type: "development",
        name: "Parques Educativos Landing Page",
        description:
            'This landing page was the internal index for the "Parques Educativos". I was responsible for the Front End design and connection with the logic part developed by the modernization team.',
        role: "Front-end",
        launchdate: "May 2016",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Visit",
                link: "https://educacion.cordoba.gob.ar/parqueseducativos/",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/parquesweb/01.png",
                alt: "Parques Educativos Landing Page",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 6,
        type: "development",
        name: "Brenda Austin Landing Page",
        description:
            "Landing page developed for Congresswoman Brenda Austin for her postulation in the mid-term elections of 2016 in Argentina. Consisted of a simple scrollable webpage.",
        role: "Main and only developer",
        launchdate: "February 2016",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/brendaaustin/01.png",
                alt: "Brenda Austin Landing Page",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 5,
        type: "development",
        name: "Parques Educativos WebApp",
        description:
            "I designed the Front-end for the self-management and registration WebApp of the Parques Educativos of Córdoba. The logic part was developed by the modernization team of the Municipality of Córdoba.",
        role: "Front-end",
        launchdate: "October 2015",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Visit",
                link: "https://educacion.cordoba.gob.ar/parqueseducativos",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/parquesapp/01.png",
                alt: "Parques Educativos WebApp",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: true,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 4,
        type: "development",
        name: "FMderecho App v1",
        description:
            "The first version of this hybrid mobile app was developed using jQuery Mobile. It was deployed for Android, Blackberry and Windows Phone.",
        role: "Main and only developer",
        launchdate: "May 2014",
        buttons: [
            {
                id: "btn1",
                type: "playstore",
                text: "Get App",
                link: "https://apkpure.com/es/fmderecho/com.franjamorada.fmderecho",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/fmderechoold/01.png",
                alt: "FM derecho mobile app version 1",
            },
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/fmderechoold/02.png",
                alt: "FM derecho mobile app version 1",
            },
        ],
        picturesQuantity: 3,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: true,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: false,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 3,
        type: "development",
        name: "Miguel Duarte Website v1 & v2",
        description:
            "The first version of his website was built in Adobe Flash. It was replaced by the second version for which I used HTML, CSS and JS with jQuery.",
        role: "Main and only developer",
        launchdate: "2013 & 2014",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/mad/01.png",
                alt: "Miguel Duarte website",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: true,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: false,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 2,
        type: "development",
        name: "F.U.Córdoba Webpage",
        description:
            "Its new website meant a new mean of communication between the Universitarian Federation and more than 100000 students of the National University of Córdoba.",
        role: "Main and only developer",
        launchdate: "2013",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/fucordoba/01.png",
                alt: "Universitarian Federation of Córdoba website",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: true,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 1,
        type: "development",
        name: "FMderecho Webpage",
        description:
            "The website was developed to improve the communication between the union and the law students of the UNC, helping with their paperwork and keeping them updated.",
        role: "Main and only developer",
        launchdate: "2013",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/cedcordoba/01.png",
                alt: "FM derecho webpage",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: true,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: true,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: true,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: true,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: true,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
];

/* 
███████╗██████╗░██╗████████╗░█████╗░██████╗░██╗░█████╗░██╗░░░░░
██╔════╝██╔══██╗██║╚══██╔══╝██╔══██╗██╔══██╗██║██╔══██╗██║░░░░░
█████╗░░██║░░██║██║░░░██║░░░██║░░██║██████╔╝██║███████║██║░░░░░
██╔══╝░░██║░░██║██║░░░██║░░░██║░░██║██╔══██╗██║██╔══██║██║░░░░░
███████╗██████╔╝██║░░░██║░░░╚█████╔╝██║░░██║██║██║░░██║███████╗
╚══════╝╚═════╝░╚═╝░░░╚═╝░░░░╚════╝░╚═╝░░╚═╝╚═╝╚═╝░░╚═╝╚══════╝
*/
export const EDI_PROJECTS = [
    {
        id: 1,
        type: "editorial",
        name: '"Los Parques Educativos de la Ciudad de Córdoba"',
        description:
            "I designed both the covers and interior of this illustrated book. It consists of 142 pages in 21x21cm with hard covers and e-book support. ISBN: 978-987-1737-03-1",
        role: "Designer",
        launchdate: "December 2019",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Download PDF",
                link: "https://educacion.cordoba.gob.ar/wp-content/uploads/sites/28/2019/12/libro-parques-educativos.pdf",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/editorial/libroparques/01.png",
                alt: "Los Parques Educativos de la Ciudad de Córdoba",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: true,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 2,
        type: "editorial",
        name: '"Notas para la enseñanza en C.E.J.A."',
        description:
            "I designed both the covers and interior of this illustrated book. It consists of 127 pages in 27x21cm with hard covers and e-book support. ISBN: 978-987-9129-79-1",
        role: "Designer",
        launchdate: "May 2019",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Download PDF",
                link: "https://educacion.cordoba.gob.ar/wp-content/uploads/sites/28/2019/06/cuaderno-de-trabajo-notas-para-la-enseanza-en-centros-educativos-de-jvenes-y-adultos.pdf",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/editorial/librocejas/01.png",
                alt: "Cuaderno de trabajo: notas para la enseñanza en Centros Educativos de Jóvenes y Adultos",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/editorial/librocejas/02.png",
                alt: "Cuaderno de trabajo: notas para la enseñanza en Centros Educativos de Jóvenes y Adultos",
            },
        ],
        picturesQuantity: 2,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: true,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 3,
        type: "editorial",
        name: "Civil Code, Penal Code & National Constitution",
        description:
            "I designed both the covers and interior of the entire set of Argentinian legal codes for the company IPSO and their association with the Law School of UNC.",
        role: "Designer",
        launchdate: "March 2017",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/editorial/codigos/01.png",
                alt: "Civil Code, Penal Code & National Constitution",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/editorial/codigos/02.png",
                alt: "Civil Code, Penal Code & National Constitution",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/editorial/codigos/03.png",
                alt: "Civil Code, Penal Code & National Constitution",
            },
        ],
        picturesQuantity: 3,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: true,
                icon: true,
                showName: "Id",
            },
        ],
    },
];

/* 
██████╗░███████╗░██████╗██╗░██████╗░███╗░░██╗
██╔══██╗██╔════╝██╔════╝██║██╔════╝░████╗░██║
██║░░██║█████╗░░╚█████╗░██║██║░░██╗░██╔██╗██║
██║░░██║██╔══╝░░░╚═══██╗██║██║░░╚██╗██║╚████║
██████╔╝███████╗██████╔╝██║╚██████╔╝██║░╚███║
╚═════╝░╚══════╝╚═════╝░╚═╝░╚═════╝░╚═╝░░╚══╝
*/
export const GDS_PROJECTS = [
    {
        id: 1,
        type: "design",
        name: "Brochures",
        description:
            "I have designed different types of brochures, magazines, catalogs, infographics, etc. They were printed in different sizes and paginations, mostly in offset and digital printing.",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/brochures/01.png",
                alt: "Brochures",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/brochures/02.png",
                alt: "Brochures",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/brochures/03.png",
                alt: "Brochures",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/brochures/04.png",
                alt: "Brochures",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/brochures/06.png",
                alt: "Brochures",
            },
            {
                id: 7,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/brochures/07.png",
                alt: "Brochures",
            },
            {
                id: 8,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/brochures/08.png",
                alt: "Brochures",
            },
        ],
        picturesQuantity: 7,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: true,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 2,
        type: "design",
        name: "Digital Flyers",
        description:
            "I have designed different types of digital flyers, social media publications, social media stories, etc. They were used in a diversity of social media publications, mainly in Facebook, Instagram and Twitter.",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/digital/01.png",
                alt: "Digital Flyers",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/digital/02.png",
                alt: "Digital Flyers",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/digital/03.png",
                alt: "Digital Flyers",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/digital/04.png",
                alt: "Digital Flyers",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/digital/05.png",
                alt: "Digital Flyers",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/digital/06.png",
                alt: "Digital Flyers",
            },
        ],
        picturesQuantity: 6,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 3,
        type: "design",
        name: "Diptychs & Triptychs",
        description:
            "I have designed different types of brochures, magazines, catalogs, infographics, etc. They were printed in different sizes and paginations, mostly in offset and digital printing.",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/01.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/02.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/03.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/04.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/05.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/06.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 7,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/07.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 8,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/08.png",
                alt: "Diptychs & Triptychs",
            },
            {
                id: 9,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/diptri/09.png",
                alt: "Diptychs & Triptychs",
            },
        ],
        picturesQuantity: 9,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 4,
        type: "design",
        name: "Flyers",
        description:
            "I have designed different types of flyers and posters. They were printed in different sizes and paginations, mostly in offset and digital printing.",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/flyers/01.png",
                alt: "Flyers",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/flyers/02.png",
                alt: "Flyers",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/flyers/03.png",
                alt: "Flyers",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/flyers/04.png",
                alt: "Flyers",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/flyers/05.png",
                alt: "Flyers",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/flyers/06.png",
                alt: "Flyers",
            },
            {
                id: 7,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/flyers/07.png",
                alt: "Flyers",
            },
        ],
        picturesQuantity: 7,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 5,
        type: "design",
        name: "Business & Institutional Folders",
        description:
            "I have vast experience in the design of business and institutional folders (w/ and wo/ pocket), blank certificates, accreditation badges, etc.",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/folders/01.png",
                alt: "Business & Institutional Folders",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/folders/02.png",
                alt: "Business & Institutional Folders",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/folders/03.png",
                alt: "Business & Institutional Folders",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/folders/04.png",
                alt: "Business & Institutional Folders",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/folders/05.png",
                alt: "Business & Institutional Folders",
            },
        ],
        picturesQuantity: 5,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 6,
        type: "design",
        name: "Letterheads, Stickers, Bookmarks & Others",
        description:
            "I have vast experience in the design of stickers, tickets, letterheads, blank certificates, pens, mugs, bookmarks, paper-bands, etc.",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/01.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/02.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/03.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/04.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/05.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/06.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
            {
                id: 7,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/07.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
            {
                id: 8,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/others/08.png",
                alt: "Letterheads, Stickers, Bookmarks & Others",
            },
        ],
        picturesQuantity: 8,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 7,
        type: "design",
        name: "Business Presentations",
        description:
            "I have designed different kind of interactive, infographic and eye-catching powerpoint presentations. ",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/presentations/01.png",
                alt: "Business Presentations",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/presentations/02.png",
                alt: "Business Presentations",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/presentations/03.png",
                alt: "Business Presentations",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/presentations/04.png",
                alt: "Business Presentations",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/presentations/05.png",
                alt: "Business Presentations",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/presentations/06.png",
                alt: "Business Presentations",
            },
        ],
        picturesQuantity: 6,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 8,
        type: "design",
        name: "Business Cards & Cards",
        description:
            "I have design different kind of cards, from business cards to credentials. They were made to be printed both in plastic or papel with different terminations and treatments.",
        role: "Designer",
        launchdate: "2010 - 2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/cards/01.png",
                alt: "Business Cards & Cards",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/cards/02.png",
                alt: "Business Cards & Cards",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/cards/03.png",
                alt: "Business Cards & Cards",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/cards/04.png",
                alt: "Business Cards & Cards",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/cards/05.png",
                alt: "Business Cards & Cards",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/cards/06.png",
                alt: "Business Cards & Cards",
            },
            {
                id: 7,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/graphic/cards/07.png",
                alt: "Business Cards & Cards",
            },
        ],
        picturesQuantity: 7,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
];

/* 
██████╗░██████╗░░█████╗░███╗░░██╗██████╗░██╗███╗░░██╗░██████╗░
██╔══██╗██╔══██╗██╔══██╗████╗░██║██╔══██╗██║████╗░██║██╔════╝░
██████╦╝██████╔╝███████║██╔██╗██║██║░░██║██║██╔██╗██║██║░░██╗░
██╔══██╗██╔══██╗██╔══██║██║╚████║██║░░██║██║██║╚████║██║░░╚██╗
██████╦╝██║░░██║██║░░██║██║░╚███║██████╔╝██║██║░╚███║╚██████╔╝
╚═════╝░╚═╝░░╚═╝╚═╝░░╚═╝╚═╝░░╚══╝╚═════╝░╚═╝╚═╝░░╚══╝░╚═════╝░
*/
export const BRA_PROJECTS = [
    {
        id: 1,
        type: "branding",
        name: "Uniqa Private Health System",
        description:
            "Uniqa is a Private Health System based in Arroyito, Córdoba. I redesigned the Branding of the company in association with Babel Agency. It consisted of a new logo and brand manual.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Visit",
                link: "https://uniqa.com.ar/",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/branding/uniqa/01.png",
                alt: "Uniqa Health System",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 2,
        type: "branding",
        name: 'International Congress of "Río de la Plata"',
        description:
            "The International Congress of Rio de la Plata was held in Córdoba in 2014 and gathered thousands of students of different law schools over South America. I worked in the full branding and merchandising design of it.",
        role: "Designer",
        launchdate: "2014",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/branding/cider/01.png",
                alt: 'International Congress of "Río de la Plata"',
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 3,
        type: "branding",
        name: "Universitarian Federation of Córdoba",
        description:
            "The Universitarian Federation of Córdoba is the union that represents all of the students of the National University of Córdoba. They trusted me to redesign their brand twice in 2015 and 2017.",
        role: "Designer",
        launchdate: "2015",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Visit",
                link: "https://www.facebook.com/fucordoba",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/branding/fuc1/01.png",
                alt: "Federación Universitaria de Córdoba",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 4,
        type: "branding",
        name: "Universitarian Federation of Córdoba",
        description:
            "The Universitarian Federation of Córdoba is the union that represents all of the students of the National University of Córdoba. They trusted me to redesign their brand twice in 2015 and 2017.",
        role: "Designer",
        launchdate: "2017",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Visit",
                link: "https://www.facebook.com/fucordoba",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/branding/fuc2/01.png",
                alt: "Federación Universitaria de Córdoba",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 5,
        type: "branding",
        name: "Franja Morada Córdoba",
        description:
            "Franja Morada is the biggest student organization of Latin America. I worked with them redesigning all the sub-brands of its Córdoba branch.",
        role: "Designer",
        launchdate: "2014",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Visit",
                link: "https://www.facebook.com/franjamoradacordoba",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/branding/franjamorada/01.png",
                alt: 'Franja Morada Córdoba"',
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 6,
        type: "branding",
        name: "MercoCED",
        description:
            "MercoCED is the network of law student centers of the CONOSUR (Argentina, Chile and Uruguay). It was created during the celebration of the 4th edition of CIDER in 2014.",
        role: "Designer",
        launchdate: "2014",
        buttons: [
            {
                id: "btn1",
                type: "website",
                text: "Visit",
                link: "https://www.facebook.com/MercoCED-718150021594404",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/branding/mercoced/01.png",
                alt: "MercoCED",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
];

/* 
░██████╗██╗░██████╗░███╗░░██╗░█████╗░░██████╗░███████╗
██╔════╝██║██╔════╝░████╗░██║██╔══██╗██╔════╝░██╔════╝
╚█████╗░██║██║░░██╗░██╔██╗██║███████║██║░░██╗░█████╗░░
░╚═══██╗██║██║░░╚██╗██║╚████║██╔══██║██║░░╚██╗██╔══╝░░
██████╔╝██║╚██████╔╝██║░╚███║██║░░██║╚██████╔╝███████╗
╚═════╝░╚═╝░╚═════╝░╚═╝░░╚══╝╚═╝░░╚═╝░╚═════╝░╚══════╝
*/
export const SIG_PROJECTS = [
    {
        id: 1,
        type: "signage",
        name: "Banners",
        description:
            "During the years I've designed lots of canvas banners and signs. Promotional, decorative and informative ones.",
        role: "Designer",
        launchdate: "2010-2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/banners/01.png",
                alt: "Banners",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/banners/02.png",
                alt: "Banners",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/banners/03.png",
                alt: "Banners",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/banners/04.png",
                alt: "Banners",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/banners/05.png",
                alt: "Banners",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/banners/06.png",
                alt: "Banners",
            },
        ],
        picturesQuantity: 3,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 2,
        type: "signage",
        name: "Signage",
        description:
            'I designed the signage for four of the five "Parques Educativos" of the city of Córdoba. It included informative, distributive and decorative signage printed in PVC, vinyl, micro-perforated vinyl and sticky paper.',
        role: "Designer",
        launchdate: "2010-2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/01.png",
                alt: "Signage",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/02.png",
                alt: "Signage",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/03.png",
                alt: "Signage",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/04.png",
                alt: "Signage",
            },
            {
                id: 5,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/05.png",
                alt: "Signage",
            },
            {
                id: 6,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/06.png",
                alt: "Signage",
            },
            {
                id: 7,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/07.png",
                alt: "Signage",
            },
            {
                id: 8,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/08.png",
                alt: "Signage",
            },
            {
                id: 9,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/09.png",
                alt: "Signage",
            },
            {
                id: 10,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/signage/10.png",
                alt: "Signage",
            },
        ],
        picturesQuantity: 10,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 3,
        type: "signage",
        name: "Plotting",
        description:
            "I designed many kind of plottings for scenario-covers, for furniture and electronics decoration, glass cover, and big surfaces in general.",
        role: "Designer",
        launchdate: "2010-2020",
        buttons: [],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/plotting/01.png",
                alt: "Plotting",
            },
            {
                id: 2,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/plotting/02.png",
                alt: "Plotting",
            },
            {
                id: 3,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/plotting/03.png",
                alt: "Plotting",
            },
            {
                id: 4,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/signage/plotting/04.png",
                alt: "Plotting",
            },
        ],
        picturesQuantity: 4,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: false,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
];

/* 
██╗░░░██╗██╗██████╗░███████╗░█████╗░
██║░░░██║██║██╔══██╗██╔════╝██╔══██╗
╚██╗░██╔╝██║██║░░██║█████╗░░██║░░██║
░╚████╔╝░██║██║░░██║██╔══╝░░██║░░██║
░░╚██╔╝░░██║██████╔╝███████╗╚█████╔╝
░░░╚═╝░░░╚═╝╚═════╝░╚══════╝░╚════╝░
*/
export const VID_PROJECTS = [
    {
        id: 1,
        type: "video",
        name: 'Loop "Congreso Municipal de Educación"',
        description:
            "Simple animated loop used as background in the screens of the 2nd Municipal Congress of Education.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=R1-1zVzv9XQ",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/loopcme/01.png",
                alt: 'Loop "Congreso Municipal de Educación"',
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: false,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 2,
        type: "video",
        name: "Swimming project in municipal schools",
        description:
            "This testimonial video was shot after a girl of one of our schools won a important local prize for swimming.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=l49WCx9y4Uo",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/vidnatacion/01.png",
                alt: "Swimming project in municipal schools",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: true,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 3,
        type: "video",
        name: "Animated IG Story for Güemes",
        description:
            "This animated loop was made as a Social Media resource for Güemes Pub.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=45wd1KeJu88",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/loopguemes1/01.png",
                alt: "Animated IG Story for Güemes",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 4,
        type: "video",
        name: "IG Ad Story for Next (Eilat)",
        description:
            "This short animated bumper ad was designed for Next (travel company) to be advertised in YouTube.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=-XwkXpQs7dc",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/bumpernext1/01.png",
                alt: "IG Ad Story for Next (Eilat)",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 5,
        type: "video",
        name: "Bumper Ad for Next (Eilat)",
        description:
            "This short animated bumper ad was designed for Next (travel company) to be advertised in YouTube.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=wxg4X47HPnI",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/bumpernext2/01.png",
                alt: "Bumper Ad for Next (Eilat)",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 6,
        type: "video",
        name: 'Loop "Parques Educativos"',
        description:
            'This short animated loop ws created to be used as a background for the screens in conferences and reunions of "Parques Educativos"',
        role: "Designer",
        launchdate: "2015",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=jXbZyRJHFI4&t=1s",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/loopparques/01.png",
                alt: 'Loop "Parques Educativos"',
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 7,
        type: "video",
        name: "Animated IG Story for Güemes",
        description:
            "This animated loop was made as a Social Media resource for Güemes Pub.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=k1J_tbPIOGk",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/loopguemes2/01.png",
                alt: "Animated IG Story for Güemes",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
    {
        id: 8,
        type: "video",
        name: "Ad Bumper for Next (Eilat)",
        description:
            "This short animated bumper ad was designed for Next (travel company) to be advertised in YouTube.",
        role: "Designer",
        launchdate: "2018",
        buttons: [
            {
                id: "btn2",
                type: "website",
                text: "View on Youtube",
                link: "https://www.youtube.com/watch?v=j_YolQrS4R4",
                style: "enabled",
            },
        ],
        pictures: [
            {
                id: 1,
                src: "https://newportfolio-alexistoledo.web.app/projects/gdesign/video/bumpernext3/01.png",
                alt: "Ad Bumper for Next (Eilat)",
            },
        ],
        picturesQuantity: 1,
        technologies: [
            {
                id: "html",
                name: "HTML5",
                used: false,
                icon: true,
            },
            {
                id: "css",
                name: "CSS3",
                used: false,
                icon: true,
            },
            {
                id: "js",
                name: "Javascript",
                used: false,
                icon: true,
            },
            {
                id: "node",
                name: "NodeJS",
                used: false,
                icon: true,
            },
            {
                id: "react",
                name: "ReactJS",
                used: false,
                icon: true,
            },
            {
                id: "rnative",
                name: "React Native",
                used: false,
                icon: false,
                showName: "React Native",
            },
            {
                id: "jquery",
                name: "jQuery",
                used: false,
                icon: false,
                showName: "jQuery",
            },
            {
                id: "firebase",
                name: "Firebase",
                used: false,
                icon: true,
            },
            {
                id: "php",
                name: "PHP",
                used: false,
                icon: false,
                showName: "PHP",
            },
            {
                id: "flash",
                name: "Adobe Flash",
                used: false,
                icon: true,
                showName: "Fl",
            },
            {
                id: "mssql",
                name: "Microsoft SQL Server",
                used: false,
                icon: false,
                showName: "MS SQL",
            },
            {
                id: "csharp",
                name: "C#",
                used: false,
                icon: true,
                showName: "C#",
            },
            {
                id: "net",
                name: ".NET",
                used: false,
                icon: false,
                showName: ".NET",
            },
            {
                id: "ai",
                name: "Adobe Illustrator",
                used: true,
                icon: true,
                showName: "Ai",
            },
            {
                id: "ps",
                name: "Adobe Photoshop",
                used: true,
                icon: true,
                showName: "Ps",
            },
            {
                id: "xd",
                name: "Adobe XD",
                used: false,
                icon: true,
                showName: "Xd",
            },
            {
                id: "pr",
                name: "Adobe Premiere Pro",
                used: false,
                icon: true,
                showName: "Pr",
            },
            {
                id: "ae",
                name: "Adobe After Effects",
                used: true,
                icon: true,
                showName: "Ae",
            },
            {
                id: "id",
                name: "Adobe InDesign",
                used: false,
                icon: true,
                showName: "Id",
            },
        ],
    },
];
